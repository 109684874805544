<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/testing/3-1/B20-20190415_01.jpg">
					<img class="" src="@/assets/images/testing/3-1/B20-20190415_02.jpg">
					<img class="" src="@/assets/images/testing/3-1/B20-20190415_03.jpg">
					<img class="" src="@/assets/images/testing/3-1/B20-20190415_04.jpg">
					<img class="" src="@/assets/images/testing/3-1/B20-20190415_05.jpg">
					<img class="" src="@/assets/images/testing/3-1/B20-20190415_06.jpg">
					
					
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"WEB漏洞扫描",
			content:"网站漏洞扫描，早检查，早防范，不要等网络安全问题出现了才后悔。那么代价也许是您没办法想象的。网站被黑、数据泄露、资金被盗、服务瘫痪等等很有可能就是因为小小的漏洞被利用了哦。漏洞扫描找专业人士，更放心，更安全心。",
            cc:"网站漏洞扫描，早检查，早防范，不要等网络安全问题出现了才后悔。那么代价也许是您没办法想象的。网站被黑、数据泄露、资金被盗、服务瘫痪等等很有可能就是因为小小的漏洞被利用了哦。漏洞扫描找专业人士，更放心，更安全心。",
			price:'在线咨询',
			url:require('../../assets/images/testing/3-1/wfkxt2.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}





}


</style>







